<template>
  <section class="log-management padding_10_15" style="margin-top: 10px">
    <div class="bg-white "  style="min-height: 60px !important; text-align: center;margin-bottom: 22px;">
      <div style="height: 15px"></div>

<!--      <el-select v-model="formSearch.operateModule" placeholder="请选择操作模块" style="margin-right: 10px" size="small">-->
<!--        <el-option label="房间" value="房间"/>-->
<!--        <el-option label="账单" value="账单"/>-->
<!--        <el-option label="合同" value="合同"/>-->
<!--        <el-option label="工单" value="工单"/>-->
<!--        <el-option label="发布" value="发布"/>-->
<!--        <el-option label="资产" value="资产"/>-->
<!--        <el-option label="密码" value="密码"/>-->
<!--      </el-select>-->
<!--      <el-select v-model="formSearch.operateType" placeholder="请选择操作类型" style="margin-right: 10px" size="small">-->
<!--        <el-option label="add" value="add"/>-->
<!--        <el-option label="update" value="update"/>-->
<!--      </el-select>-->

      <el-form :inline="true" :model="formSearch" class="demo-form-inline"  size="mini">
        <el-form-item  label="发送时间" label-width="90px">
          <!-- 2023/08/24 张晓瑜新增 -->
          <el-date-picker style="width:240px" v-model="SigningDate" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                          range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"/>
        </el-form-item>
        <el-form-item label="房源名称">
          <el-input v-model="formSearch.apartmentName" placeholder="请输入房源名称"></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="formSearch.leasorName" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input v-model="formSearch.phone" placeholder="请输入联系方式"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="formSearch.type" placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option label="催缴短信" value="1"></el-option>
            <el-option label="验证码" value="0"></el-option>
            <el-option label="密码" value="2"></el-option>
            <el-option label="其他" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
          <el-button @click="handleReset" size="small">重置</el-button>
        </el-form-item>
      </el-form>
<!--     <div style="height: 33px;background-color:#f3f3f3;width: 100%">444</div>-->
    </div>

    <r-e-table class="bg-white" ref="orderTableRef"
               :dataRequest="getLogs"
               :columns="tableColumn"
               :query="formSearch" :height="780">
      <template slot="empty">
        <el-empty/>
      </template>
<!--      <el-table-column slot="toolbar" label="操作" width="120">-->
<!--        <template slot-scope="{ row }">-->
<!--          <div class="table-tools">-->
<!--            <span class="table-btn" @click="xiangQing(row)">详情</span>-->
<!--          </div>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </r-e-table>
    <r-e-dialog title="详情" :visible.sync="detailShow" top="5vh" width="600px">
      <el-form ref="formPublish" label-width="100px" size="small">
        <el-form-item label="操作人">
          <el-input v-model="detailData.operater" disabled />
        </el-form-item>
        <el-form-item label="操作时间">
          <el-date-picker v-model="detailData.operateTime" disabled type="datetime" placeholder="选择日期时间" />
        </el-form-item>
        <el-form-item label="操作模块">
          <el-input v-model="detailData.operateModule" disabled />
        </el-form-item>
        <el-form-item label="操作类型">
          <el-input v-model="detailData.operateType" disabled />
        </el-form-item>
        <el-form-item label="旧数据">
          <el-input v-model="detailData.oldData" type="textarea" disabled :autosize="{ minRows: 2, maxRows: 4}" />
        </el-form-item>
        <el-form-item label="新数据">
          <el-input v-model="detailData.newData" type="textarea" disabled :autosize="{ minRows: 2, maxRows: 4}" />
        </el-form-item>
      </el-form>
    </r-e-dialog>
  </section>
</template>

<script>
import { tableColumn } from "./data";
import { shortmessageLogList } from "@/api/log";
export default {
  name: "shortmessage-log",
  data() {
    return {
      tableColumn:tableColumn(this),
      tableData: [],
      formSearch: {
        // operateModule: null,
        // operateType: null,
        type:null,
        leasorName:null,
        phone:null,
        apartmentName:null
      },
      detailShow: false,
      detailData: {},
      SigningDate: [], // 签约时间
    };
  },
  components:{
  },
  mounted() {
    // this.$router.go(0)
    // this.formSearch.SigningDate =[];
  //
  },
  methods: {
    getLogs(params) {
      console.log(shortmessageLogList(params))
      return shortmessageLogList(params);
    },
    handleSearch() {
      this.$refs["orderTableRef"].getTableData();
    },
    handleReset() {
      this.formSearch = {};
      this.SigningDate =[];
      Promise.resolve(this.formSearch).then(() => this.handleSearch());
    },
    xiangQing(data) {
      this.detailData = {...data};
      this.detailShow = true;
    }
  },
  watch:{
    // 签约时间
    SigningDate(value) {
      if (value && value.length !== 0) {
        let [signDt1, signDt2] = value;
        this.formSearch.startDate = signDt1;
        this.formSearch.endDate = signDt2;
      } else {
        this.formSearch.startDate = null;
        this.formSearch.endDate = null;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
/deep/  .el-input{
  height: 44px;
}
.log-management {
  padding: VH(15px) VW(15px);

  .top-bar {
    display: flex;
    margin: VH(10px) 0 VH(25px);
    padding: VH(15px) VW(15px);
    justify-content: flex-start;
  }

  /deep/ .r-e-table {
    .el-table .cell  {
      text-align: justify;
      //white-space: nowrap;
      //text-align: center;
      height: 54px;
      line-height: 54px;
      //overflow-y: auto;

    }
    .el-table .is-left .cell .el-link .el-link--inner  {
      text-align: justify;
      //white-space: nowrap;
      //text-align: center;
      font-size: 12px;
      height: 54px;
      line-height: 14px;
      overflow-y: auto;
      //margin-top: 12px;
      color: #968e8e;
    }

  }
}
/deep/ .el-select .el-input .el-select__caret{
  margin-top: -8px;
}

/deep/ .el-range-separator{
  width: 50px;
}

</style>